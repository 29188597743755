import { render, staticRenderFns } from "./species-form.vue?vue&type=template&id=aefbaba0&"
import script from "./species-form.vue?vue&type=script&lang=ts&"
export * from "./species-form.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports