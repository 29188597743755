


















































import get from 'lodash/get'
import { get as getr, post, patch, del } from '@/services/api'
import { Species } from '@/types'
import { AxiosResponse } from 'axios'

import { mapState } from 'vuex'

import bus from '@/services/event-bus'

import BnglText from '@/components/shared/bngl-text.vue'
import SpeciesForm from '@/components/shared/entities/species-form.vue'
import Split from '@/components/split.vue'
import SpeciesProperties from '@/components/model-page/secondary-view/species-properties.vue'

import findUniqName from '@/tools/find-uniq-name'
import objStrSearchFilter from '@/tools/obj-str-search-filter'
import blockHeightWoPadding from '@/tools/block-height-wo-padding'

const defaultSpecies = {
  id: undefined,
  name: '',
  valid: false,
  definition: '',
  concentration: '',
  annotation: '',
}

const searchProps = ['name', 'definition']

export default {
  name: 'species-component',
  components: {
    split: Split,
    'species-properties': SpeciesProperties,
    'species-form': SpeciesForm,
  },
  data() {
    return {
      error: false,
      deleteError: false,
      species: [],
      searchStr: '',
      tableHeight: null,
      newSpeciesModalVisible: false,
      current: { ...defaultSpecies },
      columns: [
        {
          title: 'Name',
          key: 'name',
          maxWidth: 180,
        },
        {
          title: 'Concentration',
          maxWidth: 280,
          render: (h, params) =>
            h(BnglText, {
              props: {
                entityType: 'parameter',
                value: params.row.concentration,
              },
            }),
        },
        {
          title: 'BioNetGen definition',
          render: (h, params) =>
            h(BnglText, {
              props: {
                entityType: 'species',
                value: params.row.definition,
              },
            }),
        },
        {
          title: 'Annotation',
          maxWidth: 260,
          render: (h, params) => h('span', get(params, 'row.annotation', '').split('\n')[0]),
        },
      ],
    }
  },
  async created() {
    this.species = await this.getSpecies()
  },
  mounted() {
    this.timeoutId = window.setTimeout(() => this.computeTableHeight(), 0)
    bus.$on('layoutChange', () => this.computeTableHeight())
  },
  beforeDestroy() {
    window.clearTimeout(this.timeoutId)
    bus.$off('layoutChange')
  },
  methods: {
    async getSpecies() {
      const model = this.$store.state.model

      if (!model?.id) return []

      const res: AxiosResponse<Species[]> = await getr<Species[]>('species', {
        user_id: model?.user_id,
        model_id: model?.id,
      })

      return res.data.map((s) => ({
        ...s,
        concentration: s.concentration.toString(),
      }))
    },

    add() {
      this.current = {
        ...defaultSpecies,
        name: findUniqName(this.species, 's'),
      }
      this.showNewSpeciesModal()

      this.$nextTick(() => {
        this.$refs.speciesForm.refresh()
        this.$refs.speciesForm.focus()
      })
    },

    async remove() {
      const res = await del<null>(`species/${this.current.id}`)
      if (!res) {
        this.deleteError = true
        return
      }

      this.deleteError = false

      this.current = { ...defaultSpecies }
      this.species = await this.getSpecies()
    },
    showNewSpeciesModal() {
      this.newSpeciesModalVisible = true
    },
    hideNewSpeciesModal() {
      this.newSpeciesModalVisible = false
    },

    onSpeciesSelect(species: Species) {
      this.current = species
    },
    async onOk() {
      this.error = false

      const model_id = this.$store.state.model?.id
      let res: AxiosResponse<Species> | undefined

      if (!this.current.id) res = await post<Species>('species', { ...this.current, model_id })
      else res = await patch<Species>(`species/${this.current.id}`, this.current)

      if (!res) {
        this.error = true
        return
      }

      this.hideNewSpeciesModal()

      this.species = await this.getSpecies()
    },
    computeTableHeight() {
      this.tableHeight = blockHeightWoPadding(this.$refs.mainBlock)
    },
  },
  computed: mapState({
    filteredSpecies() {
      return this.species.filter((e) => objStrSearchFilter(this.searchStr, e, { include: searchProps }))
    },
    emptyTableText() {
      return this.searchStr ? 'No matching species' : 'Create a species by using buttons below'
    },
  }),
}
